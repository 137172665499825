body,
html {
  margin: 0 !important;
  padding: 0 !important;
}
.App {
  font-family: sans-serif;
  text-align: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  margin: 0 !important;
  padding: 0 !important;
}
